import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Gradient } from "react-gradient"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCardCreator from "../components/postCardCreator"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"

const gradients = [
  ["#bd19d6", "#ea7d10"],
  ["#ff2121", "#25c668"],
]

//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogCat = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allContentfulAllContentTypes.edges
  let postCounter = 0

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Todos os conteúdos"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <div className="row" style={{ backgroundColor: "#fff" }}>
        <div className="col-12">
          <div
            className="post-content"
            style={{
              paddingTop: "5vw",
              paddingBottom: "5vw",
              textAlign: "center",
            }}
          >
            <h1
              className="post-content-header"
              style={{ marginTop: "150px", height: "200px" }}
            >
              Todos os conteúdos
            </h1>
          </div>
        </div>
        <div className="col-12">
          <div className="post-feed">
            {posts.map(({ node }) => {
              postCounter++
              return (
                <PostCardCreator
                  key={node.slug}
                  count={postCounter}
                  node={node}
                  postClass={`post`}
                />
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulAllContentTypes(
      sort: { fields: publishedDate, order: DESC }
      filter: { category: { ne: "Preview" } }
    ) {
      edges {
        node {
          title
          description
          publishedDate
          createdAt
          author
          slug
          image {
            fluid(maxWidth: 2048, quality: 100) {
              src
            }
          }
        }
      }
    }
    allContentfulContentType {
      edges {
        node {
          name
          displayField
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogCat location={props.location} props data={data} {...props} />
    )}
  />
)
